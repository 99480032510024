import gql from 'graphql-tag'

import { RECORD_CONTACT } from './fragments'

export const SUBMISSION_LIST = gql`
	query search_submission (
		$submission_status: String,
		$search_query: String,
		$contact_index: Int,
		$page_number: Int,
		$n_per_page: Int
	) {
		search_submission (
			submission_status: $submission_status,
			contact_index: $contact_index,
			search_query: $search_query,
			page_number: $page_number,
			n_per_page: $n_per_page
		) {
			page_count
			data {
				backend_key
				BestLIMS_key
				submission_type
				submission_status
				submit_date
				submitter {
					name
					name_en
				}
				sample_owner {
					name
					name_en
				}
				invoice_to {
					name
				}
				invoice {
					invoice_status
					invoice_no
				}
				reports_no
			}
		}
	}
`

export const SUBMISSION_DETAIL = gql`
	${RECORD_CONTACT}
	query get_submission ($key: String!) {
		get_submission (key: $key) {
			backend_key
			BestLIMS_key
			submission_type
			submission_status
			submit_date
			sample_received_date
			completed_date
			cancel_date
			thai_report
			english_report
			remarks
			backuser_owner
			submitter {
				...RecordContact
			}
			sample_owner {
				...RecordContact
			}
			invoice_to {
				...RecordContact
			}
			invoice {
				invoice_no
				invoice_status
			}
			reports {
				report_no
				report_version
				report_date
				report_description
				report_file {
					S3_key
					file_name
				}
			}
			mail_invoice
			mail_report_to_submitter
			mail_report_to_sample_owner
			sample_details
			submission_batches
			synced_to_BestLIMS
			last_edit_date
			BestLIMS_last_contact_time
		}
	}
`

export const SEND_SUBMISSION = gql`
	${RECORD_CONTACT}
	mutation send_submission (
		$backuser: Int,
		$submission_type: String!,
		$thai_report: Boolean!,
		$english_report: Boolean!,
		$submitter: Int!,
		$sample_owner: Int!,
		$invoice_to: Int!,
		$mail_invoice: Boolean!,
		$mail_report_to_submitter: Boolean!,
		$mail_report_to_sample_owner: Boolean!,
		$remarks: String,
		$sample_details: String
		$submission_batches: String!
	) {
		send_submission (
			backuser: $backuser,
			submission_type: $submission_type,
			thai_report: $thai_report,
			english_report: $english_report,
			submitter: $submitter,
			sample_owner: $sample_owner,
			invoice_to: $invoice_to,
			mail_invoice: $mail_invoice,
			mail_report_to_submitter: $mail_report_to_submitter,
			mail_report_to_sample_owner: $mail_report_to_sample_owner,
			remarks: $remarks,
			sample_details: $sample_details,
			submission_batches: $submission_batches
		) {
			backend_key
			BestLIMS_key
			submission_type
			submission_status
			submit_date
			sample_received_date
			thai_report
			english_report
			remarks
			submitter {
				...RecordContact
			}
			sample_owner {
				...RecordContact
			}
			invoice_to {
				...RecordContact
			}
			mail_invoice
			mail_report_to_submitter
			mail_report_to_sample_owner
			sample_details
			submission_batches
		}
	}
`

export const UPDATE_SUBMISSION = gql`
	${RECORD_CONTACT}
	mutation update_submission (
		$BestLIMS_key: String!,
		$thai_report: Boolean!,
		$english_report: Boolean!,
		$sample_owner: Int!,
		$invoice_to: Int!,
		$mail_invoice: Boolean!,
		$mail_report_to_submitter: Boolean!,
		$mail_report_to_sample_owner: Boolean!,
		$remarks: String,
		$sample_details: String
		$submission_batches: String!
	) {
		update_submission (
			BestLIMS_key: $BestLIMS_key,
			thai_report: $thai_report,
			english_report: $english_report,
			sample_owner: $sample_owner,
			invoice_to: $invoice_to,
			mail_invoice: $mail_invoice,
			mail_report_to_submitter: $mail_report_to_submitter,
			mail_report_to_sample_owner: $mail_report_to_sample_owner,
			remarks: $remarks,
			sample_details: $sample_details,
			submission_batches: $submission_batches
		) {
			backend_key
			BestLIMS_key
			submission_type
			submission_status
			submit_date
			sample_received_date
			thai_report
			english_report
			remarks
			backuser_owner
			submitter {
				...RecordContact
			}
			sample_owner {
				...RecordContact
			}
			invoice_to {
				...RecordContact
			}
			mail_invoice
			mail_report_to_submitter
			mail_report_to_sample_owner
			sample_details
			submission_batches
			synced_to_BestLIMS
		}
	}
`
