import gql from 'graphql-tag'

import { CONTACT_FIELDS } from './fragments'

export const CONTACTS_LIST = gql`
	query search_contact (
		$search_query: String,
		$contact_type: String,
		$no_primary_contacts: Boolean
		$no_admins: Boolean,
	) {
		search_contact (
			search_query: $search_query,
			contact_type: $contact_type,
			no_primary_contacts: $no_primary_contacts,
			no_admins: $no_admins,
		) {
			index
			contact_type
			name
		}
	}
`

export const CONTACT_DETAIL = gql`
	${CONTACT_FIELDS}
	query get_contact ($index: Int!) {
		get_contact (index: $index) {
			...ContactFields
			primary_contact_of
		}
	}
`

export const CREATE_CONTACT = gql`
	mutation create_contact (
		$user_index: Int,
		$contact_type: String!,
		$name: String!,
		$address: String!,
		$name_en: String,
		$address_en: String,
		$email: String,
		$phone: String,
	) {
		create_contact (
			user_index: $user_index,
			contact_type: $contact_type,
			name: $name,
			address: $address,
			name_en: $name_en,
			address_en: $address_en,
			email: $email,
			phone: $phone,
		) {
			index
		}
	}
`

export const ADD_CONTACT_TO_USER = gql`
	mutation add_contact_to_backuser (
		$user_index: Int,
		$contact_index: Int!
	) {
		add_contact_to_backuser (
			user_index: $user_index,
			contact_index: $contact_index
		)
	}
`

export const UPDATE_CONTACT = gql`
	mutation update_contact (
		$contact_index: Int!,
		$name: String,
		$address: String,
		$name_en: String,
		$address_en: String,
		$email: String,
		$phone: String,
	) {
		update_contact (
			contact_index: $contact_index,
			name: $name,
			address: $address,
			name_en: $name_en,
			address_en: $address_en,
			email: $email,
			phone: $phone,
		) {
			index
		}
	}
`