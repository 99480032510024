import groupBy from 'lodash/groupBy'
import cloneDeep from 'lodash/cloneDeep'

import { SubmissionType } from '@/enum'

export const ProcessSubmission = (submission_raw, test_methods) => {
	const submission = cloneDeep(submission_raw)
	submission.is_general = submission.submission_type === SubmissionType.GENERAL
	submission.is_disinfectant = submission.submission_type === SubmissionType.DISINFECTANT

	if (typeof submission.submission_batches === 'string') {
		submission.submission_batches = JSON.parse(submission_raw.submission_batches)
	}
	if (submission.is_general) {
		if (typeof submission.sample_details === 'string') {
			submission.sample_details = JSON.parse(submission_raw.sample_details)
		}
		submission.submission_batches.forEach(batch => {
			Object.entries(batch.tests).forEach(([department, tests]) => {
				if (tests) {
					tests.test_list = groupBy(tests.test_list.map(
						test => test_methods[department].find(
							test_info => test_info.test_key == test
						)
					), 'category')
				}
			})
		})

	} else if (submission.is_disinfectant) {
		submission.submission_batches.forEach(batch => {
			batch.is_virus = batch.test_type === 'ไวรัส'
		})
	}

	return submission
}

export const GroupTestMethods = (test_methods_raw) => {
	return groupBy(test_methods_raw, 'department')
}

export const GroupTestCategories = (test_methods) => {
	return groupBy(test_methods, 'category')
}

export const DecorateTestMethods = (test_methods) => {
	return test_methods.map(test => {
		let constraint_label = ''
		if (test.min && test.max) {
			constraint_label = `(${test.min}-${test.max} ต.ย.)`
		} else if (test.min) {
			constraint_label = `(≥ ${test.min} ต.ย.)`
		} else if (test.max) {
			constraint_label = `(≤ ${test.max} ต.ย.)`
		} else {
			constraint_label = null
		}
		return {...test, constraint_label }
	})
}

